import React from 'react';
import {
  Columns as IProps,
} from 'src/infrastructure/gatsby/types/columns';
import styled from 'styled-components';
import { Heading } from '../shared/heading';
import Columns from './template';

const Section = styled.section`
  margin: 6rem calc((100% - var(--container-width)) / 2);
`;

const ColumnsSection: React.FC<IProps> = ({
  sectionId,
  sectionTitle,
  contentWithFallbackImage,
}) => (
  <Section
    id={sectionId}
    data-test="columns-section"
  >
    {sectionTitle ? (
      <Heading>{sectionTitle}</Heading>
    ) : null}
    <Columns columns={contentWithFallbackImage} />
  </Section>
);

export default ColumnsSection;
